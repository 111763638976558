/*
  class for managing OnBoardRequest data
*/
import store from '@/store'
import axios from 'axios'
import forEach from 'lodash/forEach'
import forOwn from 'lodash/forOwn'
import { API_ROOT } from '@/API/urls'

class Address {
  constructor(data) {
    if (data.id) {
      this.id = data.id
    }
    this.type = data.type
    this.street1 = data.street1
    this.street2 = data.street2
    this.city = data.city
    this.state = data.state
    this.country = data.country
    this.postal_code = data.postal_code
  }

  get postalCode() {
    return this.postal_code
  }

  set postalCode(postalCode) {
    this.postal_code = postalCode
  }
}

function addressesAreEqual(addr1, addr2) {
  // Returns true if all parts make a case-insensitive match
  // Null and empty string are equivalent
  if (addr1.id && addr2.id && addr1.id === addr2.id) {
    return true
  }
  let result = true
  forEach(Object.getOwnPropertyNames(), (field) => {
    if (addr1[field] && addr2[field]) {
      if (addr1[field].toLowerCase() !== addr2[field].toLowerCase()) {
        result = false
      }
    } else if (addr1[field] || addr2[field]) {
      result = false
    }
  })
  return result
}

class Contact {
  constructor(id, role, type, detail) {
    if (id) {
      this.id = id
    }
    this.role = role
    this.contact = {}
    this.contact.type = type
    this.contact.detail = detail
  }
}

function isWorkPhone(contact) {
  return contact.role === 'Work Phone'
}

class OnBoardRequest {
  constructor(onBoardRequestData) {
    if (onBoardRequestData) {
      this.id = onBoardRequestData.id
      this.continuation_key = onBoardRequestData.continuation_key
      this.continuation_key_expiration = onBoardRequestData.continuation_key_expiration
      this.data = onBoardRequestData.data
    } else {
      this.data = {}
    }
    if (!this.data.person) {
      this.data.person = {}
      this.data.person.change_comment = 'Initial'
    }
    if (!this.data.scientific_area) {
      this.data.scientific_area = {}
    }
    if (!this.data.project) {
      this.data.project = {}
    }
    if (!this.data.person.addresses) {
      this.data.person.addresses = []
    }
    if (!this.data.person.contacts) {
      this.data.person.contacts = []
    }
    if (!this.data.person.logins) {
      this.data.person.logins = []
    }
    if (!this.data.person.affiliations) {
      this.data.person.affiliations = []
    }
    if (!this.data.tracks) {
      this.data.tracks = {}
    }
    if (!this.data.access_requests) {
      this.data.access_requests = {}
    }
    if (!this.data.lab_info) {
      this.data.lab_info = {}
    }
    const me = this
    forEach(this.data.person.logins, (login) => {
      me.data.access_requests[login.role] = 'Current'
    })
  }

  setTrackStepStatus(trackName, stepName, status) {
    if (this.data.tracks && this.data.tracks[trackName]) {
      this.data.tracks[trackName][stepName].value = status
    } else {
      throw new Error(`Track ${trackName} is not valid on this request.`)
    }
  }

  getTrackStepStatus(trackName, stepName) {
    const result = null
    if (this.data.tracks && this.data.tracks[trackName] && this.data.tracks[trackName][stepName]) {
      return this.data.tracks[trackName][stepName].value
    }
    return result
  }

  setProjectStatusPending() {
    this.data.project.status = 'approval_pending'
  }

  setTrackStepComplete(trackName, stepName) {
    this.setTrackStepStatus(trackName, stepName, 'complete')
  }

  setTrackStepPending(trackName, stepName) {
    this.setTrackStepStatus(trackName, stepName, 'pending')
  }

  setTrackStepIncomplete(trackName, stepName) {
    this.setTrackStepStatus(trackName, stepName, 'incomplete')
  }

  isTrackStepComplete(trackName, stepName) {
    return this.getTrackStepStatus(trackName, stepName) === 'complete'
  }

  startWithoutHarvardKey() {
    // If user does not have a Harvard Key to begin with, splice
    // the step out of the general track
    let index = -1
    for (let i = 0; i < this.data.tracks.general.order.length; ++i) {
      if (this.data.tracks.general.order[i] === 'harvard_key_verified') {
        index = i
      }
    }
    if (index > -1) {
      this.data.tracks.general.order.splice(index, 1)
    }
  }

  updateFromExistingPerson(person) {
    // Update the person data and set various steps complete
    this.person = person
    const personSteps = [
      'email_collected',
      'existing_full_name_conflict',
      'existing_full_name_checked',
      'existing_full_name_conflict',
      'existing_full_name_confirmed',
    ]
    forEach(personSteps, (stepName) => {
      this.setTrackStepComplete('general', stepName)
    })
    if (
      this.workAddress.street1
      && this.workAddress.city
      && this.workAddress.state
      && this.workAddress.country
      && this.workAddress.postalCode
      && this.workPhone.contact.detail
    ) {
      this.setTrackStepComplete('general', 'common_data_collected')
    }
    this.changeComment = 'Modifying access'
    forEach(person.logins, (login) => {
      if (login.application === 'Research Computing AD') {
        this.data.rc_username = login.username
      }
    })
  }

  emailVerified() {
    return this.isTrackStepComplete('general', 'email_verified')
  }

  get accessRequests() {
    return this.data.access_requests
  }

  set accessRequests(accessRequests) {
    this.data.access_requests = accessRequests
  }

  addAccessRequestOption(accessRequestOption) {
    if (!this.data.access_requests[accessRequestOption]) {
      this.data.access_requests[accessRequestOption] = ''
    }
  }

  get tracks() {
    return this.data.tracks
  }

  set tracks(tracks) {
    this.data.tracks = tracks
  }

  addTrack(trackName) {
    // Adds a track definition and order entry for the given track name
    const trackDefinitions = JSON.parse(sessionStorage.getItem('track_definitions'))
    this.data.tracks[trackName] = trackDefinitions[trackName]
    if (!this.data.tracks.order.includes(trackName)) {
      this.data.tracks.order.push(trackName)
    }
  }

  addTracksFromAccessRequests() {
    // Goes through all of the access requests of value "Request"
    // and adds a track of that name.
    // This should only be done after selection at GetAccessRequests
    forOwn(this.accessRequests, (val, key) => {
      if (val === 'Request') {
        this.addTrack(key)
        const commentLines = this.changeComment.split('\n')
        commentLines.push(`Adding access to ${key}`)
        this.changeComment = commentLines.join('\n')
      }
    })
    // If there is only one track (ie 'general')
    // because they started a request but had no options
    // tack on the 'finish' track
    if (this.tracks.order.length === 1) {
      this.addTrack('finish')
    }
  }

  get workAddress() {
    let wa = new Address({ type: 'Work' })
    forEach(this.data.person.addresses, (address) => {
      if (address.type === 'Work') {
        wa = new Address(address)
      }
    })
    return wa
  }

  set workAddress(workAddress) {
    let index = -1
    forEach(this.data.person.addresses, (address, i) => {
      if (address.type === 'Work') {
        index = i
      }
    })
    if (index === -1) {
      this.data.person.addresses.push(workAddress)
    } else {
      this.data.person.addresses[index] = workAddress
    }
  }

  get workPhone() {
    let wp = new Contact(null, 'Work Phone', 'Phone')
    forEach(this.data.person.contacts, (contact) => {
      if (isWorkPhone(contact)) {
        wp = contact
      }
    })
    return wp
  }

  set workPhone(workPhone) {
    let index = -1
    forEach(this.data.person.contacts, (contact, i) => {
      if (isWorkPhone(contact)) {
        index = i
      }
    })
    if (index === -1) {
      this.data.person.contacts.push(workPhone)
    } else {
      this.data.person.contacts[index] = workPhone
    }
  }

  get cnsIntranetUsername() {
    return this.data.cns_intranet_username
  }

  set cnsIntranetUsername(intranetUsername) {
    this.data.cns_intranet_username = intranetUsername
  }

  get cnsIntranetPassword() {
    return this.data.cns_intranet_password
  }

  set cnsIntranetPassword(intranetPassword) {
    this.data.cns_intranet_password = intranetPassword
  }

  // Internal user Scientific Area
  get applicationType() {
    return this.data.scientific_area.application_type
  }

  set applicationType(applicationType) {
    this.data.scientific_area.application_type = applicationType
  }

  get technicalField() {
    return this.data.scientific_area.technical_field
  }

  set technicalField(technicalField) {
    this.data.scientific_area.technical_field = technicalField
  }

  get technicalCategory() {
    return this.data.scientific_area.technical_category
  }

  set technicalCategory(technicalCategory) {
    this.data.scientific_area.technical_category = technicalCategory
  }

  get fundingSource() {
    return this.data.scientific_area.funding_source
  }

  set fundingSource(fundingSource) {
    this.data.scientific_area.funding_source = fundingSource
  }

  get useType() {
    return this.data.scientific_area.use_type
  }

  set useType(useType) {
    this.data.scientific_area.use_type = useType
  }

  get projectTitle() {
    return this.data.project.title
  }

  set projectTitle(projectTitle) {
    this.data.project.title = projectTitle
  }

  get projectDescription() {
    return this.data.project.description
  }

  set projectDescription(projectDescription) {
    this.data.project.description = projectDescription
  }

  get projectMaterials() {
    return this.data.project.materials
  }

  set projectMaterials(projectMaterials) {
    this.data.project.materials = projectMaterials
  }

  get projectInstrumentation() {
    return this.data.project.instrumentation
  }

  set projectInstrumentation(projectInstrumentation) {
    this.data.project.instrumentation = projectInstrumentation
  }

  get projectStatus() {
    return this.data.project.status
  }

  set projectStatus(projectStatus) {
    this.data.project.status = projectStatus
  }

  get estimatedStartDate() {
    return this.data.project.estimated_start_date
  }

  set estimatedStartDate(estimatedStartDate) {
    this.data.project.estimated_start_date = estimatedStartDate
  }

  get estimatedDuration() {
    return this.data.project.estimated_duration
  }

  set estimatedDuration(estimatedDuration) {
    this.data.project.estimated_duration = estimatedDuration
  }

  get person() {
    return this.data.person
  }

  set person(person) {
    this.data.person = person
  }

  get firstName() {
    return this.data.person.first_name
  }

  set firstName(firstName) {
    this.data.person.first_name = firstName
  }

  get lastName() {
    return this.data.person.last_name
  }

  set lastName(lastName) {
    this.data.person.last_name = lastName
  }

  get fullName() {
    return this.data.person.full_name
  }

  set fullName(fullName) {
    this.data.person.full_name = fullName
  }

  get primaryEmail() {
    return this.data.person.primary_email
  }

  set primaryEmail(primaryEmail) {
    this.data.person.primary_email = primaryEmail
  }

  get addresses() {
    return this.data.person.addresses
  }

  set addresses(addresses) {
    this.data.person.addresses = addresses
  }

  addAddress(address) {
    if (!this.data.person.addresses) {
      this.data.person.addresses = []
    }
    let found = false
    forEach(this.data.person.addresses, (addr) => {
      if (addressesAreEqual(addr, address)) {
        found = true
      }
    })
    if (!found) {
      this.data.person.addresses.push(address)
    }
  }

  get demographicData() {
    return {
      race: this.data.person.race,
      ethnicity: this.data.person.ethnicity,
      gender: this.data.person.gender,
      disability: this.data.person.disability,
      citizenship: this.data.person.citizenship,
      origin: this.data.person.origin,
    }
  }

  get race() {
    return this.data.person.race
  }

  set race(race) {
    this.data.person.race = race
  }

  get ethnicity() {
    return this.data.person.ethnicity
  }

  set ethnicity(ethnicity) {
    this.data.person.ethnicity = ethnicity
  }

  get gender() {
    return this.data.person.gender
  }

  set gender(gender) {
    this.data.person.gender = gender
  }

  get disability() {
    return this.data.person.disability
  }

  set disability(disability) {
    this.data.person.disability = disability
  }

  get citizenship() {
    return this.data.person.citizenship
  }

  set citizenship(citizenship) {
    this.data.person.citizenship = citizenship
  }

  get origin() {
    return this.data.person.origin
  }

  set origin(origin) {
    this.data.person.origin = origin
  }

  get expenseCode() {
    return this.data.expense_code
  }

  set expenseCode(expenseCode) {
    this.data.expense_code = expenseCode
  }

  get termsAndConditions() {
    return this.data.terms_and_conditions
  }

  set termsAndConditions(termsAndConditions) {
    this.data.terms_and_conditions = termsAndConditions
  }

  get changeComment() {
    return this.data.person.change_comment
  }

  set changeComment(changeComment) {
    this.data.person.change_comment = changeComment
  }

  addLogin(application, username, role) {
    if (!this.data.person.logins) {
      this.data.person.logins = []
    }
    let found = false
    forEach(this.data.person.logins, (login) => {
      if (login.application === application && login.username === username) {
        found = true
      }
    })
    if (!found) {
      this.data.person.logins.push({ application, username, role })
    }
  }

  get harvardKey() {
    let hkey = this.data.harvard_key
    if (!hkey && this.data.person.logins) {
      forEach(this.data.person.logins, (login) => {
        if (login.application === 'Harvard Key') {
          this.data.harvard_key = login.username
          hkey = this.data.harvard_key
        }
      })
    }
    return hkey
  }

  set harvardKey(harvardKey) {
    this.data.harvard_key = harvardKey
  }

  setHarvardKeyStepsComplete() {
    // There may be more than one harvard key step
    const me = this
    forEach(this.tracks.order, (trackName) => {
      forEach(me.tracks[trackName].order, (stepName) => {
        if (stepName === 'harvard_key_verified') {
          me.setTrackStepComplete(trackName, stepName)
        }
      })
    })
  }

  get primaryAffiliation() {
    return this.data.person.primary_affiliation
  }

  set primaryAffiliation(primaryAffiliation) {
    this.data.person.primary_affiliation = primaryAffiliation
  }

  get enteredAffiliation() {
    return this.data.entered_affiliation
  }

  set enteredAffiliation(enteredAffiliation) {
    this.data.entered_affiliation = enteredAffiliation
  }

  addAffiliation(role, slug) {
    if (!this.data.person.affiliations) {
      this.data.person.affiliations = []
    }
    let found = false
    forEach(this.data.person.affiliations, (affiliation) => {
      if (affiliation.slug === slug && affiliation.role === role) {
        found = true
      }
    })
    if (!found) {
      this.data.person.affiliations.push({ role, slug })
    }
  }

  get department() {
    return this.data?.department
  }

  set department(department) {
    this.data.department = department
  }

  get scholarType() {
    return this.data?.person?.scholar_type
  }

  set scholarType(scholarType) {
    this.data.person.scholar_type = scholarType
  }

  get pi() {
    return this.data.pi
  }

  set pi(pi) {
    this.data.pi = pi
  }

  get userAdmin() {
    return this.data.user_admin
  }

  set userAdmin(userAdmin) {
    this.data.user_admin = userAdmin
  }

  get nninAdminUsername() {
    return this.data.nnin_admin_username
  }

  set nninAdminUsername(nninAdminUsername) {
    this.data.nnin_admin_username = nninAdminUsername.toLowerCase()
  }

  setDefaultNninAdminUsername() {
    let username = this.firstName.toLowerCase()[0] + this.lastName.toLowerCase()
    username = username.replace(/[\W ]+/g, '')
    this.data.nnin_admin_username = username.slice(0, 12)
  }

  get rcUsername() {
    return this.data.rc_username
  }

  set rcUsername(rcUsername) {
    this.data.rc_username = rcUsername
  }

  get managedLabs() {
    return this.data.managed_labs
  }

  set managedLabs(managedLabs) {
    this.data.managed_labs = managedLabs
  }

  get labInfo() {
    return this.data.lab_info
  }

  set labInfo(labInfo) {
    this.data.lab_info = labInfo
  }

  get approverContacts() {
    // List of email addresses
    return this.data.approver_contacts
  }

  set approverContacts(approverContacts) {
    this.data.approver_contacts = approverContacts
  }
}

async function getTrackDefinitions() {
  const url = `${API_ROOT}/get-track-definitions/`
  return axios.get(url).then((res) => res.data)
}

function getContinuationKey() {
  // Get it from session storage
  return sessionStorage.getItem('onboard_continuation_key')
}

function setContinuationKey(continuationKey, continuationKeyExpiration) {
  sessionStorage.setItem('onboard_continuation_key', continuationKey)
  if (continuationKeyExpiration) {
    sessionStorage.setItem('onboard_continuation_key_expiration', continuationKeyExpiration)
  }
}

async function getOnBoardRequest(continuationKey) {
  // Return OnBoardRequest object by key
  const url = `${API_ROOT}/onboardrequests/`
  return axios.get(url, { params: { key: continuationKey } }).then((res) => res.data[0])
}

async function getCurrentOnBoardRequest(key) {
  // Retrieves the current onboard request
  // If a continuation key has been set in the session, that is
  // used to fetch from the database.  If there is not one,
  // the vuex store will be checked.  Otherwise, a new
  // empty one will be created
  //
  // The key argument is only passed in by the Loading component when a key
  // is initially fetched from the query params.
  let trackDefinitions = JSON.parse(sessionStorage.getItem('track_definitions'))
  if (!trackDefinitions) {
    trackDefinitions = await getTrackDefinitions()
    sessionStorage.setItem('track_definitions', JSON.stringify(trackDefinitions))
  }
  let onBoardRequest = null
  if (key) {
    setContinuationKey(key)
  }

  const continuationKey = getContinuationKey()
  if (continuationKey) {
    const onBoardRequestData = await getOnBoardRequest(continuationKey)
    onBoardRequest = new OnBoardRequest(onBoardRequestData)
  } else {
    onBoardRequest = await store.getters.getOnBoardRequest
    if (!onBoardRequest) {
      onBoardRequest = new OnBoardRequest()
      if (!onBoardRequest.data.tracks.order) {
        onBoardRequest.data.tracks.order = trackDefinitions.order
        onBoardRequest.data.tracks.general = trackDefinitions.general
      }
      store.commit('updateOnBoardRequest', onBoardRequest)
    }
  }
  return onBoardRequest
}

async function updateCurrentOnBoardRequest(onBoardRequest, post) {
  // Updates the current onBoardRequest
  // If there is a continuation key, PUT it
  // If there is not a continuation key, but email is verified, POST it
  // and set the continuation key in the session
  // At the end, update the vuex store

  let req = onBoardRequest
  let url = `${API_ROOT}/onboardrequests/`
  const continuationKey = getContinuationKey()
  let data = null
  if (continuationKey) {
    url = `${url + continuationKey}/`
    data = await axios
      .put(url, onBoardRequest)
      .then((res) => res.data)
      .catch((err) => {
        throw new Error(err)
      })
    req = new OnBoardRequest(data)
  } else if (onBoardRequest.emailVerified() || post) {
    data = await axios
      .post(url, onBoardRequest)
      .then((res) => res.data)
      .catch((err) => {
        throw new Error(err)
      })
    req = new OnBoardRequest(data)
    setContinuationKey(req.continuation_key)
  }
  await store.commit('updateOnBoardRequest', req)
  return req
}

async function updateAccountRequest(onBoardRequest) {
  // Use the update-account-request endpoint to update the
  // account request, then make sure the onBoardRequest is refetched
  // with the account request id
  const url = `${API_ROOT}/update-account-request/`
  const data = {
    continuation_key: onBoardRequest.continuation_key,
  }
  await axios.post(url, data).catch((err) => {
    throw new Error(err)
  })
  return getCurrentOnBoardRequest()
}

export { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest }
