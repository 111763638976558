<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'
import forEach from 'lodash/forEach'

export default {
  name: 'ConfirmRequestData',
  data: function () {
    return {
      loading: false,
      editing: true,
      trackName: null,
      onBoardRequest: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    next() {
      const me = this

      this.onBoardRequest.setTrackStepComplete(this.trackName, 'request_data_confirmed')
      updateCurrentOnBoardRequest(this.onBoardRequest)
        .then(() => {
          updateAccountRequest(me.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(me.onBoardRequest)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => me.showMessage(err))
    },
    formatData(data) {
      return data || '(not included)'
    },
    goToComponent(field) {
      const me = this

      switch (field) {
        case 'email':
          this.onBoardRequest.setTrackStepIncomplete('general', 'email_collected')
          this.onBoardRequest.setTrackStepIncomplete('general', 'email_notified')
          this.onBoardRequest.setTrackStepIncomplete('general', 'email_verified')
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        case 'name':
          // eslint-disable-next-line no-case-declarations
          const stepNames = [
            'existing_full_name_checked',
            'existing_full_name_conflict',
            'existing_full_name_confirmed',
          ]
          forEach(stepNames, (stepName) => {
            me.onBoardRequest.setTrackStepIncomplete('general', stepName)
          })
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        case 'demographic':
          this.onBoardRequest.setTrackStepIncomplete(this.trackName, 'demographic_data_collected')
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        case 'common_data':
          this.onBoardRequest.setTrackStepIncomplete('general', 'common_data_collected')
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        case 'project':
          this.onBoardRequest.setTrackStepIncomplete(this.trackName, 'project_data_collected')
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        case 'cns_admin_staff':
          this.onBoardRequest.setTrackStepIncomplete(this.trackName, 'get_intranet_username')
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        case 'affiliation':
          this.onBoardRequest.setTrackStepIncomplete(this.trackName, 'primary_affiliation_selected')
          if (this.onBoardRequest.tracks[this.trackName].pi_admin_contacted) {
            this.onBoardRequest.setTrackStepIncomplete(this.trackName, 'pi_admin_contacted')
          }
          updateCurrentOnBoardRequest(this.onBoardRequest)
            .then(() => {
              this.$api.mountNextComponent(this.onBoardRequest)
            })
            .catch((error) => me.showMessage(error))
          break
        default:
          break
      }
    },
  },
  computed: {
    first_name: function () {
      return this.onBoardRequest.firstName
    },
    last_name: function () {
      return this.onBoardRequest.lastName
    },
    full_name: function () {
      return this.onBoardRequest.fullName
    },
    city: function () {
      let result = null
      if (this.onBoardRequest.workAddress) {
        result = this.onBoardRequest.workAddress.city
      }
      return result
    },
    country: function () {
      let result = null
      if (this.onBoardRequest.workAddress) {
        result = this.onBoardRequest.workAddress.country
      }
      return result
    },
    postal_code: function () {
      let result = null
      if (this.onBoardRequest.workAddress) {
        result = this.onBoardRequest.workAddress.postalCode
      }
      return result
    },
    state: function () {
      let result = null
      if (this.onBoardRequest.workAddress) {
        result = this.onBoardRequest.workAddress.state
      }
      return result
    },
    street_address: function () {
      let result = null
      if (this.onBoardRequest.workAddress) {
        result = this.onBoardRequest.workAddress.street1
      }
      return result
    },
    address_type: function () {
      return this.onBoardRequest.workAddress.type
    },
    phone: function () {
      let result = null
      if (this.onBoardRequest.workPhone) {
        result = this.onBoardRequest.workPhone.contact.detail
      }
      return result
    },
    email: function () {
      return this.onBoardRequest.primaryEmail
    },
    demo: function () {
      return this.onBoardRequest.demographicData
    },
    primary_affiliation: function () {
      return this.onBoardRequest.primaryAffiliation.slug
    },
    request_type: function () {
      const unformatted = this.trackName
      let formatted = ''
      if (unformatted === 'cns_external_user') {
        formatted = 'CNS External User'
      } else if (unformatted === 'cns_admin_staff') {
        formatted = 'CNS Admin'
      } else {
        formatted = unformatted
      }
      return formatted
    },
    project: function () {
      return this.onBoardRequest.project
    },
    pi: function () {
      return this.onBoardRequest.pi.primary_email
    },
    user_admin: function () {
      return this.onBoardRequest.user_admin.primary_email
    },
    nnin_admin_username: function () {
      return this.onBoardRequest.nninAdminUsername
    },
    edit_icon_tabindex: function () {
      return this.editing ? 0 : -1
    },
  },
  mounted() {
    const me = this
    me.loading = true
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        forEach(me.onBoardRequest.accessRequests, (val, key) => {
          if (key.startsWith('cns_') && val === 'Request') {
            me.trackName = key
          }
        })
        if (!me.trackName) {
          const message = 'There are no access requests associated with this on-boarding.  It is very likely that you already have the access you need.'
          me.showMessage({ message })
        }
      })
      .finally(() => (me.loading = false))
  },
}
</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex v-if="!loading" class="lg-inner">
      <h1>Account Request Summary</h1>
      <p>
        Below is a summary of the data associated with your account request. If this is accurate, click NEXT to complete
        your request and submit for CNS administrator review.
      </p>
      <p>
        To edit any of the data, please click on the icon in the corresponding field and you will be taken back to the
        appropriate onboard request page.
      </p>
      <h2>Personal</h2>
      <v-layout class="field-layout">
        <v-flex class="field-ctr">
          <v-text-field :value="formatData(first_name)" class="field-margin" label="First Name" disabled></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('name')"
            @click="goToComponent('name')"
          >
            edit
          </v-icon>
        </v-flex>
        <v-flex class="field-ctr">
          <v-text-field :value="formatData(last_name)" label="Last Name" disabled></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('name')"
            @click="goToComponent('name')"
          >
            edit
          </v-icon>
        </v-flex>
      </v-layout>
      <v-flex class="field-ctr">
        <v-text-field :value="formatData(full_name)" label="Full Name" disabled></v-text-field>
        <v-icon
          color="primary"
          :disabled="!editing"
          :tabindex="edit_icon_tabindex"
          class="edit-btn"
          @keyup.enter="goToComponent('name')"
          @click="goToComponent('name')"
        >
          edit
        </v-icon>
      </v-flex>
      <v-flex v-if="demo">
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(demo.race)" class="field-margin" label="Race" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('demographic')"
              @click="goToComponent('demographic')"
            >
              edit
            </v-icon>
          </v-flex>
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(demo.ethnicity)" label="Ethnicity" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('demographic')"
              @click="goToComponent('demographic')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(demo.gender)" class="field-margin" label="Gender" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('demographic')"
              @click="goToComponent('demographic')"
            >
              edit
            </v-icon>
          </v-flex>
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(demo.disability)" label="Disability" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('demographic')"
              @click="goToComponent('demographic')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field
              :value="formatData(demo.citizenship)"
              class="field-margin"
              label="Citizenship"
              disabled
            ></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('demographic')"
              @click="goToComponent('demographic')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
      </v-flex>
      <h2>Contact</h2>
      <v-layout class="field-layout">
        <v-flex class="field-ctr">
          <v-text-field
            color="secondary"
            :value="formatData(email)"
            class="field-margin"
            label="Primary Email"
            disabled
          ></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('email')"
            @click="goToComponent('email')"
          >
            edit
          </v-icon>
        </v-flex>
        <v-flex class="field-ctr">
          <v-text-field :value="formatData(phone)" label="Phone" disabled></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('common_data')"
            @click="goToComponent('common_data')"
          >
            edit
          </v-icon>
        </v-flex>
      </v-layout>
      <h2>Address</h2>
      <v-layout class="field-layout">
        <v-flex class="field-ctr">
          <v-text-field
            :value="formatData(street_address)"
            class="field-margin"
            label="Street Address"
            disabled
          ></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('common_data')"
            @click="goToComponent('common_data')"
          >
            edit
          </v-icon>
        </v-flex>
        <v-flex class="field-ctr">
          <v-text-field :value="formatData(city)" label="City" disabled></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('common_data')"
            @click="goToComponent('common_data')"
          >
            edit
          </v-icon>
        </v-flex>
      </v-layout>
      <v-layout class="field-layout">
        <v-flex class="field-ctr">
          <v-text-field :value="formatData(state)" class="field-margin" label="State" disabled></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('common_data')"
            @click="goToComponent('common_data')"
          >
            edit
          </v-icon>
        </v-flex>
        <v-flex class="field-ctr">
          <v-text-field
            :value="formatData(postal_code)"
            class="field-margin"
            label="Postal Code"
            disabled
          ></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('common_data')"
            @click="goToComponent('common_data')"
          >
            edit
          </v-icon>
        </v-flex>
        <v-flex class="field-ctr">
          <v-text-field :value="formatData(country)" label="Country" disabled></v-text-field>
          <v-icon
            color="primary"
            :disabled="!editing"
            :tabindex="edit_icon_tabindex"
            class="edit-btn"
            @keyup.enter="goToComponent('common_data')"
            @click="goToComponent('common_data')"
          >
            edit
          </v-icon>
        </v-flex>
      </v-layout>
      <v-flex v-if="project">
        <h2>Project</h2>
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field
              :value="formatData(request_type)"
              class="field-margin"
              label="Type of Account Request"
              disabled
            ></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('project')"
              @click="goToComponent('project')"
            >
              edit
            </v-icon>
          </v-flex>
          <v-flex class="field-ctr">
            <v-text-field
              :value="formatData(primary_affiliation)"
              class="field-margin"
              label="Primary Affiliation"
              disabled
            ></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('affiliation')"
              @click="goToComponent('affiliation')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field
              :value="formatData(project.technical_field)"
              class="field-margin"
              label="Technical Field"
              disabled
            ></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('project')"
              @click="goToComponent('project')"
            >
              edit
            </v-icon>
          </v-flex>
          <v-flex class="field-ctr">
            <v-text-field
              :value="formatData(project.technical_category)"
              class="field-margin"
              label="Technical Category"
              disabled
            ></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('project')"
              @click="goToComponent('project')"
            >
              edit
            </v-icon>
          </v-flex>
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(project.funding_source)" label="Funding Source" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('project')"
              @click="goToComponent('project')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(pi)" class="field-margin" label="PI Email" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('affiliation')"
              @click="goToComponent('affiliation')"
            >
              edit
            </v-icon>
          </v-flex>
          <v-flex class="field-ctr">
            <v-text-field :value="formatData(user_admin)" label="User Administrator Email" disabled></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('affiliation')"
              @click="goToComponent('affiliation')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="nnin_admin_username">
        <h2>CNS Staff Admin</h2>
        <v-layout class="field-layout">
          <v-flex class="field-ctr">
            <v-text-field
              :value="formatData(nnin_admin_username)"
              class="field-margin"
              label="CNS Intranet Username"
              disabled
            ></v-text-field>
            <v-icon
              color="primary"
              :disabled="!editing"
              :tabindex="edit_icon_tabindex"
              class="edit-btn"
              @keyup.enter="goToComponent('cns_admin_staff')"
              @click="goToComponent('cns_admin_staff')"
            >
              edit
            </v-icon>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-btn class="btn" @click.prevent="next()" color="secondary">Next</v-btn>
      <div class="bottom-gradient"></div>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.field-layout {
  flex-direction: row;
}

.field-margin {
  margin-right: 1.5rem;
}

input {
  color: rgb(112, 112, 112) !important;
}

.text-header {
  margin: 1rem 0 0.5rem 0;
}

.field-ctr {
  position: relative;
}

.edit-btn {
  cursor: pointer;
  margin: 0;
  display: inline-block;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.edit-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .field-layout {
    flex-direction: column;
  }
  .field-margin {
    margin-right: 0;
  }
}
</style>
