<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'ProjectApproved',
  props: {
    trackName: String,
    orgTree: {
      type: String,
      default: 'Harvard',
    },
    ranks: {
      type: String,
      default: 'lab,department,facility,center,museum,division,institute,school,institution',
    },
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    }
  },
  data: function () {
    return {
      stepName: 'project_approved',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
      otherAffiliations: [],
      search: '',
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.primaryAffiliationForm.reset()
    },
    submit() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Begin typing your affiliation name'
      return this.loading ? `${base} (loading)` : base
    },
    filteredOrgList() {
      if (this.organizations.length) {
        // Pull out existing affiliations other than primary
        return this.organizations.filter((org) => org.slug !== this.onBoardRequest.primaryAffiliation)
      }
      return []
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        if (me.onBoardRequest.projectStatus !== 'approval_pending') {
          me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
          updateCurrentOnBoardRequest(me.onBoardRequest)
            .then((res2) => {
              this.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        }
      })
      .catch((err) => {
        me.showMessage(err)
      })
    // const me = this
    // this.loading = true

    // getCurrentOnBoardRequest()
    //   .then((res) => {
    //     me.onBoardRequest = res
    //   })
    //   .catch((error) => this.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <!-- <h1>What is your primary affiliation?</h1><p>&nbsp;</p> -->
      <h1>Project Approval</h1>
      <p>Your project data may still be pending approval, if you are approved you will automatically proceed to the next step.</p>
      <v-form id="primary-affiliation-form" ref="primaryAffiliationForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
        </v-flex>
        <!-- v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout -->
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
