<script>
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetName',
  data: function () {
    return {
      isValid: false,
      trackName: 'general',
      stepNames: ['existing_full_name_checked', 'existing_full_name_conflict', 'existing_full_name_confirmed'],
      onBoardRequest: {},
      generic: [(v) => !!v || 'This field is required.'],
      titles: ['Dr.', 'Mr.', 'Mrs.', 'Miss', 'Mx.', 'Ind.'],
      fullName: '',
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    makeFullName: debounce(function () {
      const first = this.onBoardRequest.firstName ? this.onBoardRequest.firstName : ''
      const last = this.onBoardRequest.lastName ? this.onBoardRequest.lastName : ''
      this.fullName = `${first} ${last}`
    }, 100),
    submit() {
      const me = this
      me.onBoardRequest.fullName = me.fullName

      this.$api
        .checkName(me.fullName, me.onBoardRequest.continuation_key)
        .then(() => {
          // Hang on to the entered email address before updating from existing person
          me.onBoardRequest.setTrackStepComplete(me.trackName, 'existing_full_name_checked')
          me.onBoardRequest.setTrackStepIncomplete(me.trackName, 'existing_full_name_conflict')
          me.onBoardRequest.setTrackStepIncomplete(me.trackName, 'existing_full_name_confirmed')
          updateCurrentOnBoardRequest(me.onBoardRequest)
            .then((res) => {
              this.$api.mountNextComponent(res.data)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          if (err.response.status === 404) {
            forEach(me.stepNames, (stepName) => {
              me.onBoardRequest.setTrackStepComplete(me.trackName, stepName)
            })
            updateCurrentOnBoardRequest(me.onBoardRequest)
              .then((res) => {
                this.$api.mountNextComponent(res)
              })
              .catch((err2) => {
                me.showMessage(err2)
              })
          } else {
            me.showMessage(err)
          }
        })
    },
  },
  mounted() {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        me.fullName = me.onBoardRequest.fullName
        me.$nextTick(() => this.$refs.title.focus())
      })
      .catch((error) => this.showMessage(error))
  },
}
</script>

<template>
  <v-layout justify-space-between row fill-height align-center>
    <v-flex v-if="onBoardRequest.data" class="lg-inner">
      <h1>Name</h1>
      <p v-if="onBoardRequest.fullName">Please verify the name that we retrieved from your existing account</p>
      <p v-else>
        Your email address,
        <strong>{{ onBoardRequest.primaryEmail }}</strong>
        , is not registered in the system. Let's get started with a new account. If this is an error (your email address
        should be in the system), please contact
        <a href="mailto:informatics@rc.fas.harvard.edu">Informatics Help</a>
        .
      </p>
      <v-form id="init-registration-form" ref="GetNameForm" v-model="isValid" @submit.prevent="submit">
        <v-select
          v-model="onBoardRequest.title"
          :items="titles"
          label="Title"
          ref="title"
          color="secondary"
          clearable
        ></v-select>
        <v-text-field
          v-model="onBoardRequest.firstName"
          label="First Name"
          color="secondary"
          :rules="generic"
          ref="firstName"
          required
          class="required"
          @input="makeFullName()"
        ></v-text-field>
        <v-text-field
          v-model="onBoardRequest.lastName"
          label="Last Name"
          color="secondary"
          :rules="generic"
          required
          class="required"
          @input="makeFullName()"
        ></v-text-field>
        <v-text-field
          v-model="fullName"
          label="Full Name"
          color="secondary"
          :rules="generic"
          required
          class="required"
          persistent-hint
          hint="Full name should be unique in the system.  If you have a common name, a middle initial or name is recommended."
        ></v-text-field>
        <div class="mt-3">
          <v-text-field
            :value="onBoardRequest.primaryEmail"
            label="Primary Email"
            disabled
          ></v-text-field>
        </div>
        <v-btn class="btn" :disabled="!isValid" color="secondary" type="submit" form="init-registration-form">
          Next
        </v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<style>
.header {
  margin-bottom: 1rem;
}
</style>
