<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
// import APIService from '@/API/API'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'GetProjectInfo',
  props: {
    trackName: String
  },
  data: function () {
    return {
      stepName: 'project_info',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
      otherAffiliations: [],
      search: '',
      generic: [(v) => !!v || 'This field is required.'],
      descValidator: [(v) => v !== this.descriptionPrompt || 'This field is required.'],
      startDateModal: false,
      descriptionPrompt: `Project Summary:



Objective:



Approach:




Justification:


        `
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.projectForm.reset()
    },
    submit() {
      const me = this
      this.onBoardRequest.setProjectStatusPending()
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              me.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
    }
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        if (!me.onBoardRequest.projectDescription) {
          me.onBoardRequest.projectDescription = me.descriptionPrompt
        }
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Project Details</h1>
      <div class="mb-6 text-block">
        <p>Please details of the project you're doing in the CNS facility.</p>
        <ul>
        <li>Provide a short, succinct title.</li>
        <li>Describe the project including a summary, the objective of the study, your scientific approach and the justification</li>
        <li>Additionally, include details on materials and instruments that may be needed.</li>
        </ul>
      </div>
      <v-form id="primary-affiliation-form" ref="projectForm" v-model="isValid" @submit.prevent="submit">
        <v-layout column>
          <h3>Project Title*</h3>
          <v-flex>
            <v-text-field
              v-model="onBoardRequest.projectTitle"
              color="secondary"
              ref="projectTitle"
              required
              :rules="generic"
              class="required"
            ></v-text-field>
          </v-flex>
          <v-flex>
            <h3>Estimated Length of Project*</h3>
            <v-layout row>
              <v-flex class="ma-3">
                <v-menu
                  v-model="startDateModal"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  data-cy="estimatedStartDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="onBoardRequest.estimatedStartDate"
                      color="secondary"
                      ref="estimatedStartDate"
                      required
                      :rules="generic"
                      class="required"
                      label="Estimated Start Date"
                      v-on="on"
                      read-only
                      prepend-icon="event"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="onBoardRequest.estimatedStartDate"
                    scrollable
                    @input="startDateModal = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex class="ma-3">
                <v-text-field
                  v-model="onBoardRequest.estimatedDuration"
                  color="secondary"
                  ref="estimatedDuration"
                  required
                  :rules="generic"
                  class="required"
                  label="Estimated Duration"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <h3>Description*</h3>
            <v-textarea
              v-model="onBoardRequest.projectDescription"
              color="secondary"
              ref="projectDescription"
              rows="20"
              required
              :rules="descValidator"
              class="required"
            ></v-textarea>
          </v-flex>
          <v-flex>
            <h3>Materials</h3>
            <v-textarea
              v-model="onBoardRequest.projectMaterials"
              color="secondary"
              ref="projectMaterials"
              rows="4"
            ></v-textarea>
          </v-flex>
          <v-flex>
            <h3>Instruments Needed</h3>
            <v-textarea
              v-model="onBoardRequest.projectInstrumentation"
              color="secondary"
              ref="projectInstrumentation"
              rows="4"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
