<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetPO',
  props: {
    trackName: String,
  },
  data: function () {
    return {
      stepName: 'po',
      loading: false,
      generic: [(v) => !!v || 'Required field'],
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      isValid: false,
      onBoardRequest: {},
      file: {
        name: '',
        data: null,
        stepName: this.trackName,
        trackName: this.stepName,
        category: 'PO',
      },
      postalCodeRule: [(v) => !![5].includes(v.length) || 'Please enter a valid postal code.'],
      fieldLoading: '',
      items: {
        country: [],
        state: [],
        city: [],
        postal_code: [],
      },
      workAddress: {},
      billingContact: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    setTargetItems(target) {
      this.fieldLoading = target
      // if (this.items[target].length !== 0) return
      // eslint-disable-next-line no-unused-vars
      const { country, state, city } = this.workAddress
      let params = {}

      if (target === 'country') {
        params = { target }
      } else if (target === 'state') {
        params = { target, country }
      } else if (target === 'city') {
        params = { target, country, state }
      } else {
        params = { target, country, state, city }
      }
      this.$api
        .getLocationInfo(params)
        .then((res) => {
          const data = res.data.location_info
          if (data.length === 1) {
            this.workAddress[target] = data[0]
          }
          this.items[target] = data
        })
        .finally(() => (this.fieldLoading = ''))
        .catch((err) => console.error(err))
    },
    submit() {
      this.onBoardRequest.labInfo.billing_contact_country = this.workAddress.country
      this.onBoardRequest.labInfo.billing_contact_state = this.workAddress.state
      this.onBoardRequest.labInfo.billing_contact_city = this.workAddress.city
      this.onBoardRequest.labInfo.billing_contact_postal_code = this.workAddress.postal_code
      this.onBoardRequest.labInfo.billing_contact_street1 = this.workAddress.street1

      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      this.onBoardRequest.data.request_files = [
        {
          step_name: this.stepName,
          track_name: this.trackName,
          file: this.file.name,
          category: this.file.category,
        }
      ]
      const me = this
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then(async (res) => {
          if (this.file.data) {
            await this.$api.loadRequestFile(this.file, me.onBoardRequest.id)
          }
          this.$api.mountNextComponent(res)
        })
        .catch(error => {
          const { response } = error
          if (response) {
            this.errors = response.data
          }
          if (response.status !== 400) {
            this.showMessage(error)
          }
        });
    },
    pickFile() {
      this.$refs.file.click()
    },
    onFilePicked(e) {
      const files = e.target.files
      if (files[0] === undefined) {
        this.file.name = ''
        this.file.readAsDataURL = ''
        const message = 'There is no file selected'
        this.showMessage({ message })
      } else {
        const filename = files[0].name
        this.file.name = filename
        if (this.file.name.lastIndexOf('.') <= 0) {
          const message = 'File format must have an acceptable extension (.jpg, .png, .pdf)'
          this.showMessage({ message })
        } else {
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.file.data = files[0]
            this.file.name = filename
          })
        }
      }
    },
    clearFile() {
      this.$refs.fileSelect.$el.blur()
      this.file.name = ''
      this.file.data = null
    },
    upload() {
      setTimeout(() => {
        const message = 'File upload successfull.'
        this.showMessage({ message })
        this.clearFile()
      }, 300)
    },
  },
  mounted: function () {
    const me = this
    this.loading = true
    this.file.stepName = this.stepName;
    this.file.trackName = this.trackName;

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        if (me.onBoardRequest.labInfo.organization && me.onBoardRequest.labInfo.organization.contacts) {
          const orgContact = me.onBoardRequest.labInfo.organization.contacts.find((c) => c.role === 'Billing')
          me.billingContact = orgContact.contact
        }
        let target = 'country'
        if (me.onBoardRequest.labInfo.billing_country) {
          if (me.onBoardRequest.labInfo.billing_country) {
            me.workAddress.country = me.onBoardRequest.labInfo.billing_country
            target = 'state'
          }
          if (me.onBoardRequest.labInfo.billing_state) {
            me.workAddress.state = me.onBoardRequest.labInfo.billing_state
            target = 'city'
          }
          if (me.onBoardRequest.labInfo.billing_city) {
            me.workAddress.city = me.onBoardRequest.labInfo.billing_city
            target = 'postal_code'
          }
          me.workAddress.street1 = me.onBoardRequest.labInfo.billing_street1
        } else if (me.onBoardRequest.labInfo.pi_country) {
          if (me.onBoardRequest.labInfo.pi_country) {
            me.workAddress.country = me.onBoardRequest.labInfo.pi_country
            target = 'state'
          }
          if (me.onBoardRequest.labInfo.pi_state) {
            me.workAddress.state = me.onBoardRequest.labInfo.pi_state
            target = 'city'
          }
          if (me.onBoardRequest.labInfo.pi_city) {
            me.workAddress.city = me.onBoardRequest.labInfo.pi_city
            target = 'postal_code'
          }
          me.workAddress.street1 = me.onBoardRequest.labInfo.pi_street1
        }
        me.setTargetItems(target)
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <v-layout column>
        <v-flex>
          <h1>Billing Contact</h1>
        </v-flex>
        <div v-if="onBoardRequest.labInfo && !onBoardRequest.labInfo.organization">
          <div>The person responsible for billing and payment.  May be the same as the PI / Manager.</div>
          <v-flex>
            <v-layout class="ma-1" row>
              <v-flex class="ma-1">
                <v-text-field
                  v-model="onBoardRequest.labInfo.billing_contact_name"
                  label="Name"
                  color="secondary"
                  ref="billingContactName"
                  required
                  class="required"
                ></v-text-field>
              </v-flex>
              <v-flex class="ma-1">
                <v-text-field
                  v-model="onBoardRequest.labInfo.billing_contact_email"
                  label="Email"
                  color="secondary"
                  ref="billingContactEmail"
                  :rules="emailRules"
                  required
                  class="required"
                  ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout class="ma-1" column>
              <v-combobox
                v-model="workAddress.country"
                label="Country"
                id="countrySelect"
                ref="countrySelect"
                :items="items.country"
                :loading="fieldLoading === 'country'"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
                @input="setTargetItems('state')"
              ></v-combobox>
              <v-combobox
                v-model="workAddress.state"
                label="State / Province"
                ref="stateSelect"
                id="stateSelect"
                :items="items.state"
                :loading="fieldLoading === 'state'"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
                @input="setTargetItems('city')"
              ></v-combobox>
              <v-combobox
                v-model="workAddress.city"
                label="City"
                ref="citySelect"
                id="citySelect"
                :items="items.city"
                @input="setTargetItems('postal_code')"
                :loading="fieldLoading === 'city'"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-combobox>
              <v-combobox
                v-model="workAddress.postal_code"
                label="Postal Code"
                ref="postalCodeSelect"
                id="postalCodeSelect"
                type="tel"
                @focus="setTargetItems('postal_code')"
                :loading="fieldLoading === 'postal_code'"
                :items="items.postal_code"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-combobox>
              <v-text-field
                v-model="workAddress.street1"
                label="Street Address"
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-text-field>
              <v-text-field
                v-model="onBoardRequest.labInfo.billing_phone"
                label="Phone"
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-text-field>
            </v-layout>
          </v-flex>
        </div>
        <div v-else-if="billingContact">
          <v-layout column>
              <v-flex class="ma-2">
                <span class="work-address">
                  {{ billingContact.name }}, {{ billingContact.detail }}
                  <br>
                  {{ billingContact.phone }}
                </span>
                <span class="work-address">
                  {{ billingContact.address }}
                </span>
              </v-flex>
              <v-flex class="ma-2">
                If this information is incorrect for your lab / company, please contact <a href="mailto:info@cns.fas.harvard.edu">info@cns.fas.harvard.edu</a>
              </v-flex>
            </v-layout>
        </div>
        <v-flex>
          <h1>Purchase Order</h1>
          <p>
            Click the button below to upload a picture of
            your purchase order.
          </p>
        </v-flex>
        <v-flex>
          <v-form id="get-po-form" v-model="isValid" @submit.prevent="submit">
            <v-text-field
              label="PO file"
              @click='pickFile'
              v-model='file.name'
              prepend-icon='attach_file'
              hint="Upload PO PDF"
              color="secondary"
              required
              :rules="generic"
            >
            </v-text-field>
            <input
              type="file"
              style="display: none"
              ref="file"
              accept="application/pdf"
              @change="(e)=>onFilePicked(e)"
            />
            <v-layout row justify-end>
              <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<style>
.upload-ctr {
  margin: 1.5rem 0;
  flex-direction: row;
}

.filename-ctr {
  display: inline-block;
}
#get-po-form {
  margin-top: 1rem;
}
.filename {
  font-style: italic;
}
.work-address {
  font-size: 1.2em;
  white-space: pre-line;
}
</style>
