<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
// import APIService from '@/API/API'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'GetLabInfo',
  props: {
    trackName: String
  },
  data: function () {
    return {
      stepName: 'lab_info',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizationNames: [],
      otherAffiliations: [],
      search: '',
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      generic: [(v) => !!v || 'Required field'],
      postalCodeRule: [(v) => !![5].includes(v.length) || 'Please enter a valid postal code.'],
      fieldLoading: '',
      items: {
        country: [],
        state: [],
        city: [],
        postal_code: [],
      },
      piContact: {},
      workAddress: {},
      piOrg: {},
      scholarTypes: ['Professor', 'Postdoctoral Fellow', 'Graduate Student', 'Undergraduate Student', 'Visiting Scholar', 'Research Associate', 'Employee', 'Other'],
      institutionTypes: ['University', '4-year college', '2-year college', 'K-12', 'Small Company (< 300 employees)', 'Large Corporation (> 300 employees)', 'State or Federal Government', 'International Institute or Corporation'],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.labInfoForm.reset()
    },
    setTargetItems(target) {
      this.fieldLoading = target
      // if (this.items[target].length !== 0) return
      // eslint-disable-next-line no-unused-vars
      const { country, state, city } = this.workAddress
      let params = {}

      if (target === 'country') {
        params = { target }
      } else if (target === 'state') {
        params = { target, country }
      } else if (target === 'city') {
        params = { target, country, state }
      } else {
        params = { target, country, state, city }
      }
      this.$api
        .getLocationInfo(params)
        .then((res) => {
          const data = res.data.location_info
          if (data.length === 1) {
            this.workAddress[target] = data[0]
          }
          this.items[target] = data
        })
        .finally(() => (this.fieldLoading = ''))
        .catch((err) => console.error(err))
    },
    getPiContact() {
      console.log('getPiContact', this.organizationNames)
      const me = this
      this.$set(me.onBoardRequest.labInfo, 'organization', null)
      this.$set(me.onBoardRequest.labInfo, 'pi_name', null)
      this.$set(me.onBoardRequest.labInfo, 'pi_email', null)
      this.$set(me.onBoardRequest.labInfo, 'pi_phone', null)

      const matchingOrg = this.organizationNames.find((org) => org.name === this.onBoardRequest.labInfo.lab_name)
      console.log('matchingOrg', matchingOrg)
      console.log('this.onBoardRequest.labInfo.lab_name', this.onBoardRequest.labInfo.lab_name)
      if (!matchingOrg) {
        return
      }
      this.$api
        .getOrganization(matchingOrg.ifxorg, 'PI')
        .then((res) => {
          console.log(res.data)
          const piOrg = res.data
          const piContact = piOrg.contacts.find((c) => c.role === 'PI')
          if (piContact) {
            this.$set(me.onBoardRequest.labInfo, 'organization', piOrg)
            this.$set(me.onBoardRequest.labInfo, 'pi_name', piContact.contact.name)
            this.$set(me.onBoardRequest.labInfo, 'pi_email', piContact.contact.detail)
            this.$set(me.onBoardRequest.labInfo, 'pi_phone', piContact.contact.phone)
            this.$set(me.workAddress, 'address', piContact.contact.address)
          }
          console.log('piContact', piContact)
        })
        .catch((err) => me.showMessage(err))
    },
    submit() {
      const me = this
      if (!this.onBoardRequest.labInfo.organization) {
        this.onBoardRequest.labInfo.pi_country = this.workAddress.country
        this.onBoardRequest.labInfo.pi_state = this.workAddress.state
        this.onBoardRequest.labInfo.pi_city = this.workAddress.city
        this.onBoardRequest.labInfo.pi_postal_code = this.workAddress.postal_code
        this.onBoardRequest.labInfo.pi_street1 = this.workAddress.street1
      }

      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          updateAccountRequest(me.onBoardRequest)
            .then((res2) => {
              me.$api.mountNextComponent(res2)
            })
            .catch((err) => {
              me.showMessage(err)
            })
        })
        .catch((err) => {
          me.showMessage(err)
        })
    }
  },
  computed: {
    searchPlaceholder: function () {
      const base = 'Begin typing your affiliation name'
      return this.loading ? `${base} (loading)` : base
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    this.$api.getOrgNames('Harvard', null, 'Company,Institute,Institution')
      .then((res) => {
        me.organizationNames = res.data.sort((a, b) => a.name.localeCompare(b.name))
        me.loading = false
      })
      .catch((error) => me.showMessage(error))

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        if (!me.onBoardRequest.labInfo) {
          me.onBoardRequest.labInfo = {}
        }
        me.workAddress.country = me.onBoardRequest.labInfo.pi_country
        me.workAddress.state = me.onBoardRequest.labInfo.pi_state
        me.workAddress.city = me.onBoardRequest.labInfo.pi_city
        me.workAddress.postal_code = me.onBoardRequest.labInfo.pi_postal_code
        me.workAddress.street1 = me.onBoardRequest.labInfo.pi_street1
        me.setTargetItems('country')
        const autocompleteList = ['countrySelect', 'stateSelect', 'citySelect', 'postalCodeSelect']
        me.$nextTick(() => {
          autocompleteList.forEach((name) => {
            me.$refs[name].$refs.input.addEventListener('focus', this.onFocus, true)
          })
          if (me.workAddress.country && me.workAddress.state && me.workAddress.city) {
            me.$refs.submit.$el.focus()
          }
        })
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Institution or Company</h1><p>&nbsp;</p>
      <v-form id="lab-info-form" ref="labInfoForm" v-model="isValid" @submit.prevent="submit">

        <v-flex>
          <v-layout row class="ma-1">
            <v-flex class="ma-1">
              <v-combobox v-if="onBoardRequest.labInfo"
                :items="organizationNames"
                key="organization-list"
                label="Institution or Company Name"
                ref="orgSelect"
                autocomplete="new-password"
                item-text="name"
                item-value="name"
                :return-object="false"
                prepend-icon="search"
                :loading="loading"
                hide-no-data
                v-model="onBoardRequest.labInfo.lab_name"
                hint="Start typing to pick from the list or enter a new value"
                persistent-hint
                @change="getPiContact()"
              ></v-combobox>
            </v-flex>
          </v-layout>
          <v-layout row class="ma-1">
            <v-flex class="ma-1">
              <v-select
                prepend-icon="search"
                v-model="onBoardRequest.labInfo.institution_type"
                :items="institutionTypes"
                label="Institution Type"
                ref="institutionType"
                color="secondary"
                clearable
                required
                :rules="[(v) => !!v || 'This field is required.']"
                class="required"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row class="ma-1">
            <v-flex class="ma-1">
              <v-select
                prepend-icon="search"
                v-model="onBoardRequest.scholarType"
                :items="scholarTypes"
                label="Your position at the institution or company"
                ref="scholarType"
                color="secondary"
                clearable
                required
                :rules="[(v) => !!v || 'This field is required.']"
                class="required"
              ></v-select>
            </v-flex>
          </v-layout>
          <h3>PI / Manager</h3>
          <div v-if="onBoardRequest.labInfo && !onBoardRequest.labInfo.organization">
            <div>The person primarily responsible for the project</div>
            <v-layout class="ma-1" row>
              <v-flex class="ma-1">
                <v-text-field  v-if="onBoardRequest.labInfo"
                  v-model="onBoardRequest.labInfo.pi_name"
                  label="Name"
                  color="secondary"
                  ref="piName"
                  required
                  class="required"
                >
                </v-text-field>
              </v-flex>
              <v-flex class="ma-1">
                <v-text-field  v-if="onBoardRequest.labInfo"
                  v-model="onBoardRequest.labInfo.pi_email"
                  label="Email"
                  color="secondary"
                  ref="piEmail"
                  :rules="emailRules"
                  required
                  class="required"
                  >
                </v-text-field>
              </v-flex>
            </v-layout>
            <h3>PI / Manager Address</h3>
            <v-layout class="ma-1" column>
              <v-combobox
                v-model="workAddress.country"
                label="Country"
                id="countrySelect"
                ref="countrySelect"
                :items="items.country"
                :loading="fieldLoading === 'country'"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
                @input="setTargetItems('state')"
                :disabled="!!onBoardRequest.labInfo.organization"
              ></v-combobox>
              <v-combobox
                v-model="workAddress.state"
                label="State / Province"
                ref="stateSelect"
                id="stateSelect"
                :items="items.state"
                :loading="fieldLoading === 'state'"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
                @input="setTargetItems('city')"
              ></v-combobox>
              <v-combobox
                v-model="workAddress.city"
                label="City"
                ref="citySelect"
                id="citySelect"
                :items="items.city"
                @input="setTargetItems('postal_code')"
                :loading="fieldLoading === 'city'"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-combobox>
              <v-combobox
                v-model="workAddress.postal_code"
                label="Postal Code"
                ref="postalCodeSelect"
                id="postalCodeSelect"
                type="tel"
                @focus="setTargetItems('postal_code')"
                :loading="fieldLoading === 'postal_code'"
                :items="items.postal_code"
                open-on-clear
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-combobox>
              <v-text-field
                v-model="workAddress.street1"
                label="Street Address"
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-text-field>
              <v-text-field  v-if="onBoardRequest.labInfo"
                v-model="onBoardRequest.labInfo.pi_phone"
                label="Phone"
                :rules="generic"
                color="secondary"
                required
                class="required"
              ></v-text-field>
            </v-layout>
          </div>
          <div v-else-if="onBoardRequest.labInfo">
            <v-layout column>
              <v-flex class="ma-2">
                <span class="work-address">
                  {{ onBoardRequest.labInfo.pi_name }}, {{ onBoardRequest.labInfo.pi_email }}
                  <br>
                  {{ onBoardRequest.labInfo.pi_phone }}
                </span>
                <span class="work-address">
                  {{ workAddress.address }}
                </span>
              </v-flex>
              <v-flex class="ma-2">
                If this information is incorrect for your lab / company, please contact <a href="mailto:info@cns.fas.harvard.edu">info@cns.fas.harvard.edu</a>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#lab-info-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
h3 {
  margin-top: 2em;
}
.work-address {
  font-size: 1.2em;
  white-space: pre-line;
}
</style>
