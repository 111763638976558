<script>
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetEmail',
  data: function () {
    return {
      onBoardRequest: null,
      trackName: 'general',
      stepName: 'email_collected',
      isValid: false,
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      errors: {},
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => {
          me.showMessage(error)
        })
    },
  },
  mounted() {
    const me = this
    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        me.$nextTick(() => me.$refs.primaryEmail.focus())
      })
      .catch((error) => this.showMessage(error))
  },
}
</script>

<template>
  <v-layout v-if="onBoardRequest" align-center justify-space-between row fill-height>
    <v-flex>
      <h1>Email Address</h1>
      <p>
        Please enter your email address, prefereably the one you have used with FAS Informatics applications (MiniLIMS,
        Spinal, HeRS, Fiine, CBSN) or Research Computing in the past. If you have not previously signed up for any FAS
        Informatics applications, it is best to use your "official" Harvard email.
      </p>
      <p>
        If the field below has been pre-filled via Harvard Key, it is best to use that address unless you do not
        regularly access it.
      </p>
      <v-form id="check-email-form" ref="checkEmailForm" v-model="isValid" @submit.prevent="submit">
        <v-text-field
          color="secondary"
          v-model="onBoardRequest.primaryEmail"
          :rules="emailRules"
          label="Primary Email"
          ref="primaryEmail"
          :error-messages="errors.primary_email"
        ></v-text-field>
        <v-btn class="btn" :disabled="!isValid" color="secondary" type="submit" form="check-email-form">Next</v-btn>
      </v-form>
    </v-flex>
  </v-layout>
</template>
