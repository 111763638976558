<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'TermsAndConditions',
  props: {
    trackName: String,
  },
  data: function () {
    return {
      stepName: 'terms_and_conditions',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      search: '',
      generic: [(v) => !!v || 'This field is required.'],
    }
  },
  methods: {
    ...mapActions(['showMessage']),
    submit() {
      const me = this
      this.onBoardRequest.setTrackStepComplete(this.trackName, this.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          me.onBoardRequest = res
          me.$api.mountNextComponent(res)
        })
        .catch((err) => {
          me.showMessage(err)
        })
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Terms and Conditions</h1><p>&nbsp;</p>
      <p>Users must agree to the T & C for using the facility
      General Terms and Conditions of CNS facility usage for Harvard students, faculty,
      faculty sponsored visitors, and other Harvard personnel (“USER”):
      Please read and fully understand the enclosed General Terms and Conditions and then
      sign where appropriate:</p>
      <p>1. USER must be an CNS User in order to use CNS facilities.</p>
      <p>2. USER certifies that he/she will follow all applicable Harvard University policies regarding conduct and research while using CNS facilities.</p>
      <p>3. USER certifies to follow all CNS policies regarding use of the facilities. Please consult with CNS website: www.cns.fas.harvard.edu for general rules and policies. Please consult with the appropriate CNS Staff for facility specific rules and policies.</p>
      <p>4. USER certifies that, where appropriate, acknowledgement will be given to the Harvard Center for Nanoscale Systems in any of its sponsored publications or presentations that result from work performed with CNS facilities or staff.</p>
      <p>5. USER acknowledges that any work done, or services provided, by CNS and its employees are on a reasonable effort basis. USER accepts ultimate responsibility for the progress and results of their project.</p>
      <p>6. USER will operate all instruments in a safe and professional manner, consistent with the operating instructions.</p>
      <p>7. USER represents that his/her knowledge of microfabrication, cleanroom equipment, and/or imaging equipment is adequate to permit the safe pursuit of the research work in conjunction with the USER’S specific project. USER acknowledges that CNS has the right to immediately prohibit further use of facilities, if CNS, in its sole discretion, believes that the USER has breached this representation.</p>
      <p>8. USER certifies that he/she will under no circumstances schedule time in his or her name for another user (whether other user is qualified or not), give out his or her Scheduling Tool User Name or Password for use by others, or give other users access to his or her HUID or generic facilities access card for any card reader access controlled facilities. CNS reserves the right to deny future access to USER in the event of willful breach of this policy.</p>
      <p>9. USER acknowledges CNS’ right to amend, abridge, alter, delete, add, or change any Terms and Conditions as deemed necessary by CNS with or without any written notification.</p>
      <p>10. USER Certifies that he/she has filled out the Application Form, Proposal, and Registration form truthfully to the best of his/her knowledge. USER also has read and fully understands the General Terms and Conditions:</p>
      <v-form id="terms-and-conditions-form" ref="termsAndConditionsForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-checkbox
            label="Accept terms & conditions"
            color="info"
            v-model="onBoardRequest.termsAndConditions"
            :rules="generic"
            required
            true-value="Accepted"
            false-value="Rejected"
          ></v-checkbox>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Submit</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
