<script>
/* Note that this creates / updates an account request and so can be used at the end of an onboard request */
import { mapActions } from 'vuex'
import { getCurrentOnBoardRequest, updateCurrentOnBoardRequest } from '@/onBoardRequest'

export default {
  name: 'GetScientificArea',
  props: {
    trackName: String,
    helpEmail: {
      type: String,
      default: 'informatics@rc.fas.harvard.edu',
    }
  },
  data: function () {
    return {
      stepName: 'scientific_area',
      loading: false,
      isValid: false,
      onBoardRequest: {},
      organizations: [],
      otherAffiliations: [],
      search: '',
      applicationTypes: ['Research', 'Education'],
      useTypes: ['On Site', 'Remote Use Only'],
      // Temp data binding
      applicationType: '',
      technicalFieldOptions: [
        'Electronics',
        'Physics',
        'MEMS',
        'Geology and Earth Sciences',
        'Optics and Photonics',
        'Chemistry',
        'Life Science',
        'Materials',
        'Mechanics',
        'Medicine',
        'Process Technology',
        'Enter your own',
      ],
      technicalField: '',
      technicalCategoryOptions: [
        'Processing & Fabrication',
        'Imaging & Characterization ',
      ],
      fundingSourceOptions: [
        'NSF',
        'SRC',
        'DOD',
        'State',
        'DOE',
        'NIH',
        'University',
        'Private',
        'International',
        'Other federal',
      ],
      fundingSource: '',
      useType: '',
    }
  },
  watch: {
    technicalField: {
      handler: function (newVal) {
        if (newVal !== 'Enter your own') {
          this.$set(this.onBoardRequest, 'technicalField', newVal)
        } else {
          this.$set(this.onBoardRequest, 'technicalField', '')
        }
      },
      deep: true
    },
    fundingSource: {
      handler: function (newVal) {
        if (newVal !== 'Other federal') {
          this.$set(this.onBoardRequest, 'fundingSource', newVal)
        } else {
          this.$set(this.onBoardRequest, 'fundingSource', '')
        }
      },
      deep: true
    },
  },
  methods: {
    ...mapActions(['showMessage']),
    onFocus() {
      this.$refs.orgSelect.isMenuActive = true
    },
    resetForm() {
      this.$refs.primaryAffiliationForm.reset()
    },
    submit() {
      const me = this
      me.onBoardRequest.setTrackStepComplete(me.trackName, me.stepName)
      updateCurrentOnBoardRequest(me.onBoardRequest)
        .then((res) => {
          this.$api.mountNextComponent(res)
        })
        .catch((error) => me.showMessage(error))
    },
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
      })
      .catch((error) => me.showMessage(error))
  },
}
</script>
<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex class="lg-inner">
      <h1>Scientific Area</h1><p>&nbsp;</p>
      <p>Please fill out the form below.</p>
      <v-form id="primary-affiliation-form" ref="primaryAffiliationForm" v-model="isValid" @submit.prevent="submit">
        <v-flex>
          <v-select
            v-model="onBoardRequest.applicationType"
            :items="applicationTypes"
            label="Application Type"
            ref="applicationType"
            color="secondary"
            clearable
            required
            class="required"
          ></v-select>
          <v-select
            v-model="technicalField"
            :items="technicalFieldOptions"
            label="Technical Field"
            color="secondary"
            ref="technicalField"
            required
            class="required"
          ></v-select>
          <v-text-field v-if="technicalField === 'Enter your own'"
            v-model="onBoardRequest.technicalField"
            label="Enter your technical field"
            color="secondary"
            ref="technicalField"
            required
            class="required"
          ></v-text-field>
          <v-select
            v-model="onBoardRequest.technicalCategory"
            :items="technicalCategoryOptions"
            label="Technical Category"
            color="secondary"
            ref="technicalCategory"
            required
            class="required"
          ></v-select>
          <v-select
            v-model="fundingSource"
            :items="fundingSourceOptions"
            label="Funding Source"
            color="secondary"
            ref="fundingSource"
            required
            class="required"
          ></v-select>
          <v-text-field v-if="fundingSource === 'Other federal'"
            v-model="onBoardRequest.fundingSource"
            label="Enter other federal funding source"
            color="secondary"
            ref="fundingSource"
            required
            class="required"
          ></v-text-field>
          <v-select
            v-model="onBoardRequest.useType"
            :items="useTypes"
            label="Use Type"
            ref="useType"
            color="secondary"
            clearable
            required
            class="required"
          ></v-select>
        </v-flex>
        <v-layout row justify-end>
          <v-btn @click.prevent="submit" role="button" class="btn" color="secondary" :disabled="!isValid">Next</v-btn>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<style lang="css" scoped>
.text-block {
  margin: 1rem 0;
}
.text-hint {
  margin-top: 2rem;
}
.text-action {
  margin-bottom: 1rem;
}
#primary-affiliation-form {
  margin-top: 1rem;
}
.pi-admin-field {
  margin: 1rem 0;
}
</style>
