<script>
import { getCurrentOnBoardRequest, updateAccountRequest } from '@/onBoardRequest'

export default {
  name: 'CompletedRequest',
  data: function () {
    return {
      onboardRequest: {},
    }
  },
  mounted: function () {
    const me = this
    this.loading = true

    getCurrentOnBoardRequest()
      .then((res) => {
        me.onBoardRequest = res
        updateAccountRequest(me.onBoardRequest)
          .catch((err) => {
            me.showMessage(err)
          })
      })
      .catch((error) => me.showMessage(error))
  }
}

</script>

<template>
  <v-layout align-center justify-space-between row fill-height>
    <v-flex>
      <h1>
        Your request has been completed.
      </h1>
    </v-flex>
  </v-layout>
</template>
